import axios from 'axios'

export const state = {
    token: null
}

export const actions = {
    init({ dispatch }) {
        dispatch('validate')
    },

    async logIn({ commit, dispatch, getters }, { email, password } = {}) {
        if (getters.loggedIn) return dispatch('validate')
        try {
            let url = 'https://admin.abrageo.abracol.co/ABRAGEO/loginAppUser'
            // let url = 'http://localhost:4000/ABRAGEO/loginAppUser'
            let values = {
                email: email,
                pwd: password
            }
            let result = await axios.post(url, values);
            localStorage.setItem('zona', result.data.zona);
            localStorage.setItem('profile', result.data.profile);
            commit('SET_CURRENT_USER', result.data.token);
            return result.data;
        } catch (error) {
            console.log(error);
            return 'error';
        }

    },
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.token = newValue
        localStorage.setItem('auth.currentUser', JSON.stringify(newValue))
    }
}

export const getters = {
    loggedIn(state) { return !!state.currentUser },
    token: state => state.token
}


