import axios from 'axios'
import store from '@/state/store'
export const state = {
    Serie: [],
    Label: []
}

export const actions = {
    async loadData({ commit }) {
        let zona = localStorage.getItem('zona');
        let token = store.state.auth.token;
        let result = await axios.post("ABRAGEO/GvaloresDia", { zona }, { headers: { Authorization: `Bearer ${token}` } });
        let valorPedido = [];
        let dias = [];
        result.data.data.forEach((element) => {
            valorPedido.push(element.valorPedido);
            dias.push(element.dia)
        });
        commit("SET_CURRENT_SERIES", valorPedido);
        commit("SET_CURRENT_LABELS", dias);
    },
}

export const mutations = {
    SET_CURRENT_SERIES(state, value) {
        state.Serie = value;
    },
    SET_CURRENT_LABELS(state, value) {
        state.Label = value;
    }
}


export const getters = {
    Serie: state => state.Serie,
    Label: state => state.Label
}