
export default [
    {
        path: '/',
        name: 'login',
        component: () => import('../views/pages/account/login')
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('../views/pages/mercadeo/graficas.vue'),
        meta: { requireAuth: true }
    },
    {
        path: '/mercadeo/ficha-cliente',
        name: 'Ficha Cliente',
        component: () => import('../views/pages/mercadeo/ficha-cliente.vue'),
        meta: { requireAuth: true }
    },
    {
        path: '/mercadeo/gestion-diaria',
        name: 'Gestion Diaria',
        component: () => import('../views/pages/mercadeo/gestion-diaria.vue'),
        meta: { requireAuth: true }
    },
    {
        path: '/mercadeo/detalle-orden',
        name: 'Detalle Orden',
        component: () => import('../views/pages/mercadeo/detalle-orden.vue'),
        meta: { requireAuth: true }
    },
    {
        path: '/mercadeo/sales-stadistic',
        name: 'Estadisticas de Ventas',
        component: () => import('../views/pages/mercadeo/sales-stadistic.vue'),
        meta: { requireAuth: true }
    },
    {
        path: '/mercadeo/graficas',
        name: 'Graficas',
        component: () => import('../views/pages/mercadeo/graficas.vue'),
        meta: { requireAuth: true }
    },
    {
        path: '/ventas/nacionales/hoja-vida',
        name: 'Hoja de Vida',
        component: () => import('../views/pages/ventas-nacionales/hoja-vida.vue'),
        meta: { requireAuth: true }
    },
    {
        path: '/ventas/nacionales/gestion-comercial',
        name: 'Gestion Comercial',
        component: () => import('../views/pages/ventas-nacionales/gestion-comercial.vue'),
        meta: { requireAuth: true }
    },
    {
        path: '/ventas/nacionales/validacion-admin',
        name: 'Validacion Administrativa',
        component: () => import('../views/pages/ventas-nacionales/validacion-admin.vue'),
        meta: { requireAuth: true }
    },
    {
        path: '/ruteros/rutero/add',
        name: 'Rutero ADD',
        component: () => import('../views/pages/ruteros/rutero-add.vue'),
        meta: { requireAuth: true }
    },
    {
        path: '/recorridos/virtuales/recorrido-virtual',
        name: 'Recorrido Virtual',
        component: () => import('../views/pages/recorridos-virtuales/recorrido-virtual.vue'),
        meta: { requireAuth: true }
    },
    {
        path: '/recorridos/virtuales/mapa',
        name: 'Mapa',
        component: () => import('../views/pages/recorridos-virtuales/mapa.vue'),
        meta: { requireAuth: true }
    },
    {
        path: '/recorridos/virtuales/mapa-puntos',
        name: 'Mapa De Puntos',
        component: () => import('../views/pages/recorridos-virtuales/mapa-puntos.vue'),
        meta: { requireAuth: true }
    },
    {
        path: '/shopping/creacion/formulario',
        name: 'Creacion De Formularios',
        component: () => import('../views/pages/shopping/creacion-formulario.vue'),
        meta: { requireAuth: true }
    },
    {
        path: '/shopping/formularios',
        name: 'Formularios',
        component: () => import('../views/pages/shopping/formularios.vue'),
        meta: { requireAuth: true }
    },
    {
        path: '/shopping/resultados',
        name: 'Resultados',
        component: () => import('../views/pages/shopping/resultados.vue'),
        meta: { requireAuth: true }
    },
    {
        path: '/shopping/detalle/:name',
        name: 'Detalle',
        component: () => import('../views/pages/shopping/detalle.vue'),
        meta: { requireAuth: true }
    },
    {
        path: '/whatsapp/asesorias',
        name: 'Whatsapp',
        component: () => import('../views/pages/whatsapp/informationPerson.vue'),
        meta: { requireAuth: true }
    },
    {
        path: '/recaudo/peru',
        name: 'Recaudo Peru',
        component: () => import('../views/pages/recaudos/peru.vue'),
        meta: { requireAuth: true }
    }
]
