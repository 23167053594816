import axios from 'axios'
import store from '@/state/store'
export const state = {
    Name1: "Product1",
    Name2: "Product2",
    Name3: "Product3",
    Value1: 0,
    Value2: 0,
    Value3: 0,
}

export const actions = {

    async pieAdviser({ commit }) {
        let zona = localStorage.getItem('zona');
        let token = store.state.auth.token;
        let result = await axios.post("ABRAGEO/GmayorVentaAse", { zona }, { headers: { Authorization: `Bearer ${token}` } });
        commit("SET_CURRENT_VALUES_1", result.data.data[0]);
    },

    async pieDistributor({ commit }) {
        let zona = localStorage.getItem('zona');
        let token = store.state.auth.token;
        let result = await axios.post("ABRAGEO/GmayorVentaDist", { zona }, { headers: { Authorization: `Bearer ${token}` } });
        commit("SET_CURRENT_VALUES_2", result.data.data[0]);
    },

    async pieCity({ commit }) {
        let zona = localStorage.getItem('zona');
        let token = store.state.auth.token;
        let result = await axios.post("ABRAGEO/GmayorVentaCiu", { zona }, { headers: { Authorization: `Bearer ${token}` } });
        commit("SET_CURRENT_VALUES_3", result.data.data[0]);
    }
}

export const mutations = {
    SET_CURRENT_VALUES_1(state, value) {
        if(typeof value === 'undefined'){
            return
        }
        if(typeof value.asesor === 'undefined'){
            value.asesor = "Sin Asesor"
        }
        state.Name1 = value.asesor
        state.Value1 = value.valorPedido
    },
    SET_CURRENT_VALUES_2(state, value) {
        if(typeof value === 'undefined'){
            return
        }
        if(typeof value.distribuidor !== 'undefined'){
            value.distribuidor = "Sin Distribuidor"
        }
        state.Name2 = value.distribuidor
        state.Value2 = value.valorPedido
    },
    SET_CURRENT_VALUES_3(state, value) {
        if(typeof value === 'undefined'){
            return
        }
        if(typeof value.ciudad !== 'undefined'){
            value.ciudad = "Sin Ciudad"
        }
        state.Name3 = value.ciudad
        state.Value3 = value.valorPedido
    },
}


export const getters = {
    Name1: state => state.Name1,
    Name2: state => state.Name2,
    Name3: state => state.Name3,
    Value1: state => state.Value1,
    Value2: state => state.Value2,
    Value3: state => state.Value3

}